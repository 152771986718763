import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";

import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header 
        roundedHeaderButton={true} 
      />
      <ContactUsForm 
        description={"We can't wait to hear about your educational projects !"}
      />
      <ContactDetails
        description={""}
        cards={[
          {
            title: "France",
            description: (
              <>
                <Address>
                  <AddressLine>Le petit sable</AddressLine>
                  <AddressLine>18290 Mareuil sur arnon</AddressLine>
                </Address>
                <Email>joffrey.gonin@codingflare.com</Email>
                {/* <Phone>+1 (203) 991-6988</Phone> */}
              </>
            )
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
