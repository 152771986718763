import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";


import cmsScreenshotImageSrc from "images/learningflare-cms.png";
import appScreenshotImageSrc from "images/learningflare-app.png";
import appWhiteScreenshotImageSrc from "images/learningflare-app-white.png";

import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";

import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

import { ReactComponent as ActivityIcon } from "feather-icons/dist/icons/activity.svg";
import { ReactComponent as BarChartIcon } from "feather-icons/dist/icons/bar-chart.svg";



import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero 
        roundedHeaderButton={true} 
      />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
        description={
          `
            Our solution is based on dynamic algorithms to produce personalised training sessions and evaluations.
            The system regularly evaluates the user’s skills and needs, and makes him learn what he needs the most.
          `
        }


        cards={[
          {
            title: 'Regular Evaluations',
            description: "The system keeps a follow-up of user's performance over time, displayed with charts",
            imageSrc: FastIconImage,
          },
          {
            title: 'Populational monitoring',
            description: 'A transparent populational monitoring showing progression over time. A clear tracker of Return On Investments (ROI).',
            imageSrc: ShieldIconImage,
          },
          {
            title: 'Personalized trainings',
            description: `
              Each user has personalised training based on category performance and consolidation phase.
            `,
            imageSrc: ReliableIconImage,
          },
          {
            title: 'Content training',
            description: 'The system determines the level of expertise on each learning content for each user.',
            imageSrc: FastIconImage,
            
          },
          {
            title: 'Memory consolidation maximised',
            description: "Training algorithms are based on neuroscientific studies to maximise memory consolidation.",
            imageSrc: SimpleIconImage,
          },
          {
            title: 'Design customisation',
            description: `We empower our client with white labeling on their solution and thus reinforce their brand.`,
            imageSrc: CustomizeIconImage,
          },
        ]}



        // The client can define the level of importance of each learning category; 
        // it allowes to prioritize a specific learning category over another one

      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        // imageSrc={heroScreenshotImageSrc}
        imageSrc={appWhiteScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        description={`
          You provide us with your idea; we will turn it into a fully-functional dedicated product.
          Intuitive ergonomy - Learning algorithms based on neuroscientific facts - Designed to favour engagement -  Transparent ROI - White labeling to reinforce your brand
        `}

      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        // imageSrc={macHeroScreenshotImageSrc}
        imageSrc={cmsScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        steps={
          [
            {
              heading: 'Exploration',
              description: 'We define with you the need, the categories structure, the content, the adapted device (web or mobile).'
            },
            {
              heading: 'Content production',
              description: 'We start producing the learning elements to populate the system. (Assisted or not with AI)'
            },
            {
              heading: 'Deployment',
              description: 'We send invitations to your users so that they start using your educational device.'
            },
          ]
        }
      />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        description={'Our goal is to provide efficient educational devices to serve people best. We make it reliable, affordable and transparent'}
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: ActivityIcon,
            title: "Reliable",
            description: "We constantly work to improve efficiency and fiability.",
            iconContainerCss: tw`bg-blue-300 text-blue-800`
          },
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BarChartIcon,
            title: "Transparency",
            description: "We provide a constant proof of return on investment by providing a monthly overview of population progress.",
            iconContainerCss: tw`bg-blue-300 text-blue-800`
          },
        ]}
      />
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      /> */}
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        description={'We can answer them. You can also contact us, we will happily talk to you.'}
        faqs={[
          {
            question: "Is the application/website easily customizable ?",
            answer:
              "Yes, we offer white labeling. You can add your brand and adapt the colors."
          },
        ]}
      />
      <GetStarted
        text={'Universities and companies all over the world are happily using LearningFlare.'}
        primaryLinkText={'Demo'}
        primaryLinkUrl={'/contact'}
        secondaryLinkText={'Contact Us'}
        secondaryLinkUrl={'/contact'}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
